define("discourse/plugins/discourse-zoom/discourse/initializers/zoom-composer", ["exports", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _composer, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = {
    name: "zoom-composer",
    initialize() {
      _composer.default.serializeOnCreate("zoom_id", "zoomId");
      _composer.default.serializeOnCreate("zoom_webinar_title", "zoomWebinarTitle");
      _composer.default.serializeOnCreate("zoom_webinar_start_date", "zoomWebinarStartDate");
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        var _dec, _obj;
        api.decorateWidget("post:before", dec => {
          if (dec.canConnectComponent && dec.attrs.firstPost) {
            if (!dec.attrs.cloaked) {
              return dec.connect({
                component: "post-top-webinar",
                context: "model"
              });
            }
          }
        });
        api.modifyClass("controller:topic", (_dec = (0, _decorators.observes)("model.postStream.loaded"), (_obj = {
          pluginId: "discourse-zoom",
          _addWebinarClass() {
            const webinar = this.get("model.webinar");
            if (webinar) {
              document.querySelector("body").classList.add("has-webinar");
            } else {
              document.querySelector("body").classList.remove("has-webinar");
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "_addWebinarClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "_addWebinarClass"), _obj)), _obj)));
      });
    }
  };
});